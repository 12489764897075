import React from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import get from "lodash.get";
import { RichText } from "prismic-reactjs";

import Container from "../../styles/Container";

import arrow from "../../images/arrow.svg";
import HelmetInfo from "../../components/HelmetInfo";

const Wrapper = styled.div`
  padding-top: 26px;
  padding-bottom: 48px;
  @media (min-width: 560px) {
    padding-top: 48px;
    padding-bottom: 70px;
    display: flex;
  }
`;

const Left = styled.div`
  margin-bottom: 30px;
  @media (min-width: 560px) {
    margin-bottom: 0px;
    width: 40%;
    margin-right: 40px;
  }
  @media (min-width: 800px) {
    margin-right: 70px;
  }
`;

const Right = styled.div`
  @media (min-width: 560px) {
    width: 60%;
  }
`;

const Image = styled.img`
  width: 100%;
  max-width: 380px;
`;

const Name = styled.h1`
  margin-bottom: 2px;
  font-size: 30px;
  line-height: 42px;
  font-weight: 900;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 44px;
    line-height: 58px;
  }
`;

const Position = styled.h2`
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 28px;
    font-size: 22px;
    line-height: 26px;
  }
`;

const Description = styled.div`
  p {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 18px;
  }
  a {
    text-decoration: underline;
  }
`;

const OuterContainer = styled.div`
  background-color: ${(p) => p.theme.lightBlue};
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 26px;
  padding-bottom: 26px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 52px;
    padding-bottom: 52px;
  }
`;

const FooterLeft = styled.div`
  margin-right: 16px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-right: 48px;
  }
`;

const FooterRight = styled.div`
  margin-left: 16px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-left: 48px;
  }
`;

const FooterHeading = styled.h1`
  font-size: 16px;
  line-height: 22px;
  font-weight: 900;
  max-width: 130px;
  margin-bottom: 8px;
  @media (min-width: 440px) {
    max-width: 100%;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 28px;
    line-height: 40px;
  }
  :hover {
    text-decoration: underline;
  }
`;

const FooterList = styled.ul`
  font-size: 12px;
  line-height: 26px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    font-size: 20px;
    line-height: 40px;
  }
`;

const FooterListItem = styled.li`
  :hover {
    text-decoration: underline;
  }
`;

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
`;
const NextPerson = styled(Link)`
  display: block;
`;

const ArrowLeft = styled.img`
  height: 12px;
  margin-right: 6px;
`;
const ArrowRight = styled.img`
  height: 12px;
  margin-left: 6px;
  transform: rotate(180deg);
`;

const TeamMember = ({ data, location: { state } }) => {
  let fromExecutive = get(state, "fromExecutive");
  const execTeam = data.prismicExecutiveLeadership.data.team;
  const boardTeam = data.prismicBoardOfDirectors.data.team;
  let currentTeam;

  if (fromExecutive === undefined) {
    if (
      execTeam.findIndex((member) => {
        if (member.team_member.document) {
          return (
              member.team_member.document.data.name === data.prismicPerson.data.name
          );
        } else {
          return false
        }
      }) === -1
    ) {
      currentTeam = boardTeam;
      fromExecutive = false;
    } else {
      currentTeam = execTeam;
      fromExecutive = true;
    }
  } else {
    currentTeam = fromExecutive ? execTeam : boardTeam;
  }

  const currentMemberIndex = currentTeam.findIndex((member) => {
    if (member.team_member.document) {
      return (
          member.team_member.document.data.name === data.prismicPerson.data.name
      );
    } else {
      return false
    }
  });

  const nextMemberIndex =
    currentMemberIndex < currentTeam.length - 1 ? currentMemberIndex + 1 : 0;
  const nextMember = currentTeam[nextMemberIndex];

  const lastMemberIndex =
    currentMemberIndex === 0 ? currentTeam.length - 1 : currentMemberIndex - 1;
  const lastMember = currentTeam[lastMemberIndex];

  return (
    <>
      <HelmetInfo page_data={data.prismicPerson.data} />
      <Container>
        <Wrapper>
          <Left>
            {data.prismicPerson.data.image && (
              <Image
                src={data.prismicPerson.data.image.url}
                alt={data.prismicPerson.data.name}
              />
            )}
          </Left>
          <Right>
            {data.prismicPerson.data.name && (
              <Name>{data.prismicPerson.data.name}</Name>
            )}
            {data.prismicPerson.data.position && (
              <Position>{data.prismicPerson.data.position}</Position>
            )}
            {data.prismicPerson.data.description && (
              <Description>
                <RichText
                  render={data.prismicPerson.data.description.richText}
                />
              </Description>
            )}
            <Navigation>
              {lastMember && lastMember.team_member.document && lastMember.team_member.document.data.name && (
                <NextPerson
                  to={`/members/${lastMember.team_member.document.uid}`}
                  state={{ fromExecutive }}
                >
                  <ArrowLeft src={arrow} alt="" />
                  {lastMember.team_member.document.data.name}
                </NextPerson>
              )}
              {nextMember && nextMember.team_member.document && nextMember.team_member.document.data.name && (
                <NextPerson
                  to={`/members/${nextMember.team_member.document.uid}`}
                  state={{ fromExecutive }}
                >
                  {nextMember.team_member.document.data.name}
                  <ArrowRight src={arrow} alt="" />
                </NextPerson>
              )}
            </Navigation>
          </Right>
        </Wrapper>
      </Container>
      <OuterContainer>
        <Container>
          <Footer>
            {execTeam && (
              <FooterLeft>
                <FooterHeading>
                  <Link to="/executive-leadership">Executive Leadership</Link>
                </FooterHeading>
                <FooterList>
                  {execTeam.map((member, index) => {
                    if (member.team_member.document) {
                      return (
                          <FooterListItem key={index}>
                            <Link
                                to={`/members/${member.team_member.document.uid}`}
                                state={{ fromExecutive: true }}
                            >
                              {member.team_member.document.data.name}
                            </Link>
                          </FooterListItem>
                      );
                    } else {
                      return null
                    }
                  })}
                </FooterList>
              </FooterLeft>
            )}
            {boardTeam && (
              <FooterRight>
                <FooterHeading>
                  <Link to="/board-of-directors">Board of Directors</Link>
                </FooterHeading>
                <FooterList>
                  {boardTeam.map((member, index) => {
                    return (
                      <FooterListItem key={index}>
                        <Link
                          to={`/members/${member.team_member.document.uid}`}
                          state={{ fromExecutive: false }}
                        >
                          {member.team_member.document.data.name}
                        </Link>
                      </FooterListItem>
                    );
                  })}
                </FooterList>
              </FooterRight>
            )}
          </Footer>
        </Container>
      </OuterContainer>
    </>
  );
};

export default TeamMember;

export const query = graphql`
  query ($uid: String!) {
    prismicPerson(uid: { eq: $uid }) {
      data {
        name
        position
        description {
          richText
        }
        image {
          url
          alt
        }
      }
    }
    prismicExecutiveLeadership {
      data {
        team {
          team_member {
            document {
              ... on PrismicPerson {
                uid
                data {
                  name
                }
              }
            }
          }
        }
      }
    }
    prismicBoardOfDirectors {
      data {
        team {
          team_member {
            document {
              ... on PrismicPerson {
                uid
                data {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;
